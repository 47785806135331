// Customizable Area Start
import { IBlock } from '../../../../../framework/src/IBlock';
import { Message } from '../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../framework/src/RunEngine';
import { RouteComponentProps } from 'react-router-dom';

export const configJSON = require("../config.js");

export interface Props extends RouteComponentProps {
	accountId: number;

	selfAlertCount: number;
	selfLeaveCount: number;
	showMaxAlert: boolean;
	showMaxLeave: boolean;
	currentUser: any;
	isRoomModarated: any;

	isHost: boolean;
	isModerator: boolean;

	handleAlertCount: (x: number) => void;
	handleLeaveCount: (x: number) => void;
	handleMaxAlert: () => void;
	handleMaxLeave: () => void;

	retract_leave_status: any;
	startTime: string;
}

interface S {
	anchorEl: any,
	activeMenu: string,
	menu: string,
	subMenu: string

	leaveRequest: boolean;
	callRequestId: number;

	alert_request_sent_count: number;
}

interface SS {
	id: any;
}

export default class DropdownmenuController extends BlockComponent<Props, S, SS> {

	callRequestMessageId: string
	retractRequestMessageId: string

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage)
		];

		this.state = {
			anchorEl: null,
			activeMenu: 'main',

			menu: "",
			subMenu: "",

			leaveRequest: false,
			callRequestId: -1,
			alert_request_sent_count: 0
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		super.componentDidMount();

		//if room are same and callRequest exist
		if (localStorage.getItem('oldRoomId') === this.props.history.location.pathname.split('/')[2] && localStorage.getItem('callRequestAccountId')) {
			if (this.props.accountId === parseInt(localStorage.getItem('callRequestAccountId') || "-2")) {
				this.setState({ leaveRequest: true, callRequestId: parseInt(localStorage.getItem("callRequestId") || "-1") });
			}
		}

		if (typeof this.props.retract_leave_status === 'string') {
			this.setState({ leaveRequest: false });
		} else {
			this.setState({ leaveRequest: true });
		}
	}

	async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
		const { alert_request_sent_count: count } = this.state;
		const alert_request_sent_count = this.props.currentUser?.alert_request_sent_count;

		if (alert_request_sent_count && alert_request_sent_count > count) {
			this.setState({ alert_request_sent_count: alert_request_sent_count });
		}
	}

	async receive(from: string, message: Message) {
		// runEngine.debugLog('Message Received', message);

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			let apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
			let errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
			let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

			if (this.callRequestMessageId === apiCallId) {
				if (errorJson || responseJson?.errors) {
					// Error thorwing by the backend server
					if (responseJson.errors[0].message === "Cant send leave request again") {
						this.props.handleLeaveCount(1);
						this.setState({ leaveRequest: true })
					}
					else if (responseJson.errors[0].message === "Cant send alert more than 3 times.") {
						this.props.handleAlertCount(3);
					}
					console.log(errorJson);
					return;
				}
				if (responseJson.data?.attributes?.name === "leave") {
					//! storing call request id and whom i requested to in local storage
					console.log("CALL REQUEST ID" + responseJson?.data?.id + "AND TO WHOM:" + responseJson.data?.attributes?.account_id + "ARE SAVED IN LOCAL STORAGE");
					localStorage.setItem('callRequestId', responseJson?.data?.id);
					localStorage.setItem('callRequestAccountId', responseJson.data?.attributes?.account_id);
					// localStorage.setItem('oldRoomId', this.props.history.location.pathname.split('/')[2])

					this.setState({ callRequestId: responseJson.data.id, leaveRequest: true });
					this.props.handleLeaveCount(responseJson.data.attributes.leave_request_count);//! 1
				} else if (responseJson.data.attributes.name === "alert") {
					this.props.handleAlertCount(responseJson.data.attributes.alert_request_count);
				}
			}
			else if (this.retractRequestMessageId === apiCallId) {
				if (errorJson || responseJson?.errors) {
					console.log(errorJson);
					return;
				}
				this.setState({ callRequestId: -1, leaveRequest: false });
				// storing call request id in local storage
				localStorage.setItem('callRequestId', "-1");
				// localStorage.setItem('oldRoomId', this.props.history.location.pathname.split('/')[2])
				this.props.handleLeaveCount(0);//! 0
			}
		}

	}
	handleClick = (event: React.MouseEvent<HTMLElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null, activeMenu: 'main' });
	};

	handleMenu = (menuProp: string) => {
		this.setState({ activeMenu: menuProp, menu: menuProp });
	};

	handleSubMenu = (submenuProp: string) => {
		this.setState({ anchorEl: null, activeMenu: 'main', subMenu: submenuProp }, () => {
			this.increseAlertCount();
			this.makeCallRequest();
		});
	}
	handleRetractRequest = () => {
		this.makeRetractRequest();
		this.handleClose();
	}

	increseAlertCount = () => {
		this.setState(prev => ({ alert_request_sent_count: prev.alert_request_sent_count + 1 }));
	}

	handleAlert = (props: any) => {
		console.log("ALERT COUNT:" + this.state.alert_request_sent_count);
		if (this.state.alert_request_sent_count >= 3) {
			alert("You have already reached the maximum number of three alerts.");
			return;
		}
		this.handleMenu(props);
	}

	makeCallRequest = () => {
		let reqMsg1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
		reqMsg1.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.callRequestHttpsEndpoint);
		reqMsg1.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

		const header = {
			token: localStorage.getItem("token")
		};

		reqMsg1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

		let formdata = new FormData();
		formdata.append("name", this.state.menu);
		formdata.append("account_id", this.props.accountId.toString());
		formdata.append("room_id", this.props.history.location.pathname.split('/')[2]);
		formdata.append("reason", this.state.subMenu);

		reqMsg1.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

		this.callRequestMessageId = reqMsg1.messageId;
		runEngine.sendMessage(reqMsg1.id, reqMsg1);
	}

	makeRetractRequest = () => {
		let reqMsg2 = new Message(getName(MessageEnum.RestAPIRequestMessage));
		reqMsg2.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.retractRequestEndpoint);
		reqMsg2.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

		const header = {
			token: localStorage.getItem("token")
		};

		reqMsg2.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

		let formdata = new FormData();
		// //! accessing callRequestId form localStorage if page was refreshed
		// let callRequestId = localStorage.getItem("callRequestId") || "";
		// formdata.append("call_request_id", this.state.callRequestId.toString() || callRequestId);
		if (this.state.callRequestId === -1) return;
		formdata.append("call_request_id", this.state.callRequestId.toString());
		reqMsg2.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

		this.retractRequestMessageId = reqMsg2.messageId;
		runEngine.sendMessage(reqMsg2.id, reqMsg2);
	}
}
// Customizable Area End