// Customizable Area Start
import firebase from 'firebase/app';
import 'firebase/messaging';

// types
interface FcmMessage {
  data: Data;
  notification: Notification;
  from: string;
  priority: string;
  collapse_key: string;
}

interface Data {
  [key: string]: string;
}

export const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyA-su31F4JN5qBYQlEbJcbVvUyVvIUxjrg',
  authDomain: 'ylanes1.firebaseapp.com',
  projectId: 'ylanes1',
  storageBucket: 'ylanes1.appspot.com',
  messagingSenderId: '899494597149',
  appId: '1:899494597149:web:cbcc54f491ef31fb22ecf4',
  measurementId: 'G-86Q2Z5B4XD'
});

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseAPI);
} else {
  firebase.app(); // if already initialized, use that one
}

// initialize analytics
const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

// create messaging instance
let messaging: null | firebase.messaging.Messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// register ServiceWorker, if not installed already.
export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then(serviceWorker => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope'
          }
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

// return FCM token to send notification
export const getFirebaseToken = () => {
  return new Promise<string | null>((resolve, reject) => {
    if (Notification.permission === 'granted') {
      getOrRegisterServiceWorker()
        .then(() => messaging!.getToken())
        .then(firebaseToken => {
          // compare with the token in local storage
          const storedToken = localStorage.getItem('firebaseToken');
          if (storedToken !== firebaseToken) {
            // if different, update the token in local storage
            localStorage.setItem('firebaseToken', firebaseToken);
          }
          resolve(firebaseToken);
        })
        .catch(err => {
          resolve(null); // Resolve with null in case of an error
        });
    } else {
      resolve(null); // Resolve with null if notification permission is not granted
    }
  });
};

// handle notificaton if user already using the web-app
export const onForegroundMessage = (
  callback: (payload: FcmMessage) => void
) => {
  if (!messaging) return;

  return messaging.onMessage(payload => {
    console.log('Received foreground message.', payload);
    callback(payload);
  });
};

// track for token refresh, also send the new token to BE if changes
export const onTokenRefresh = (
  updateToken: (refreshedToken: string) => void
) => {
  // onTokenRefresh has been deprecated on firebase v8.
  // but as we're using v7, we've to use this method.
  if (!messaging) return;

  return messaging.onTokenRefresh(() => {
    if (!messaging) return;

    messaging
      .getToken()
      .then(refreshedToken => {
        console.log('Token refreshed.');

        // Send Instance ID token to app server.
        updateToken(refreshedToken);
      })
      .catch(err => {
        console.log('Unable to retrieve refreshed token ', err);
      });
  });
};

// Customizable Area End
