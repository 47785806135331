import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { Client as ConversationsClient } from "@twilio/conversations";
export const configJSON = require("../config");
import { AlertProps } from '@material-ui/lab/Alert';
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  user_name: string;
  profile_img: string;
  last_msg?: string;
  isSelected: boolean;
  handleClick: (c_sid: string, user_id: number | string) => void;
  conversation_sid: string;
  last_msg_time?: string;
  isTyping: boolean;
  unread_count?: number;
  user_id: string | number;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  chatData: any;
  isMenuOpen: any;
  isDeleteModalVisible: any;
  messages: any;
  loading: boolean;
  chattingWith: {
    user_name: string;
    full_name: string;
    profile_image: string;
    conversation_id: string;
    last_message?: string;
    messages: any[],
    user_id: number | null
  },
  typing: {
    conversation_id: string | null,
    isTyping: boolean
  },
  users: any[];
  hasAlreadyChat: boolean;
  allConversations: any[];
  selectedConversation: any;
  boundConversations: Set<string>,
  search: {
    isVisible: boolean,
    searchKeyword: string
  }
  filterUsers: any[];
  isWindowBlur: boolean;
  isConfirmationOpen: boolean;
  connectedConversationIds: string[];
  friends: any[],
  coming_from_profile: boolean;
  snackbar: { type: AlertProps["severity"], message: string, isOpen: boolean };
  isMobile: boolean;
  isUsersLoading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  conversationClientNew: any;
  createMessageAPICallId: string;
  twilioAPICallID: string;
  loadUserAPICallId: string;
  loadMessagesAPICallId: string;
  disconnectAPIId: string;
  loadfriendsAPIId: string;
  conversationsClient: any;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  fetchConversationId: string;
  createConnectionAPIId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      isMenuOpen: false,
      isDeleteModalVisible: false,
      loading: false,
      messages: [],
      chatData: [],
      chattingWith: {
        user_name: "",
        full_name: "",
        profile_image: "",
        conversation_id: "",
        messages: [],
        user_id: null
      },
      typing: {
        conversation_id: null,
        isTyping: false
      },
      users: [],
      hasAlreadyChat: false,
      allConversations: [],
      selectedConversation: null,
      boundConversations: new Set(),
      search: {
        isVisible: false,
        searchKeyword: ""
      },
      filterUsers: [],
      isWindowBlur: false,
      isConfirmationOpen: false,
      connectedConversationIds: [],
      friends: [],
      coming_from_profile: false,
      snackbar: { type: "success", message: '', isOpen: false },
      isMobile: false,
      isUsersLoading: false,
    };
    this.inputRef = React.createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  getChatIdFromUrl(url: string): string | null {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('chat_id');
  }

  handleUsersResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.loadUserAPICallId) {
      this.setState({ isUsersLoading: false });
      this.getTwilioToken();
      if (responseJson?.data?.chats.length > 0) {
        const rawUsers = responseJson?.data?.chats;

        const users = rawUsers.map((obj: any) => {
          return { ...obj, created_at: new Date(obj.created_at) };
        });

        const sortedDesc = users.sort(
          (objA: any, objB: any) => Number(objB.created_at) - Number(objA.created_at),
        );

        const chatId = this.getChatIdFromUrl(window.location.href);

        this.setState({ users: sortedDesc, filterUsers: sortedDesc }, () => {
          this.state.users.forEach((user) => {
            const current_id = sessionStorage.getItem("conversation_sid");
            if (current_id === user.conversation_sid) {
              this.setState({ hasAlreadyChat: true });
            };

            // open chat, if url has chat_id
            if (chatId && chatId === user.conversation_sid) {
              const user_data = user?.user_data;
              this.setState(
                (prev) => ({
                  chattingWith: {
                    ...prev.chattingWith,
                    profile_image: user.profile_image,
                    user_name: user_data.user_name,
                    conversation_id: chatId,
                    full_name: user_data.full_name,
                    user_id: user_data.id,
                  },
                  coming_from_profile: false,
                }),
                () => {
                  this.loadMessages();
                }
              );
            }
          })
        });
      }
    }
  }

  handleMessagesResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.loadMessagesAPICallId) {
      this.setState(prev => ({
        chattingWith:
        {
          ...prev.chattingWith, messages: responseJson?.data?.messages.reverse() || [],
        },
      }), () => {
        this.setState({ loading: false }, () => {
          // focus on send message input
          setTimeout(() => {
            this.inputRef.current?.focus();
          }, 500);
        });
        if (!this.state.selectedConversation) return;

        const messages = this.state.chattingWith.messages;
        if (messages.length === 0) return;

        const indx = messages[messages.length - 1].index;
        if (indx < 0) return;

        this.state.selectedConversation.setAllMessagesRead();

        this.state.selectedConversation.getUnreadMessagesCount().then((unread_count: number) => {
          const selectUserIndx = this.state.filterUsers.findIndex((user) => user.user_data.id === this.state.chattingWith.user_id);
          if (selectUserIndx === -1) return;

          const tempUsers = [...this.state.filterUsers];
          const user = tempUsers[selectUserIndx];
          // decrese from the notification count, if user click on chat before unread messages loads.

          sessionStorage.setItem("remove_count", JSON.stringify(unread_count || 0));
          window.dispatchEvent(new Event("storage"));
          user.unread = 0;
          this.setState({ filterUsers: tempUsers });
        });
      });
    }
  }

  handleFriendsReponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.loadfriendsAPIId) {
      if (responseJson?.data) {
        const tempFriends: any = [];
        responseJson.data.map((each: any) => {
          const indx = tempFriends.findIndex((data: any) => data?.user_data?.other_user_id === each?.attributes?.other_user_id);
          if (indx === -1) {
            tempFriends.push({
              is_connected: true, profile_image: each.attributes.profile_image
              , conversation_sid: "", user_data: {
                id: each.attributes.other_user_id, ...each.attributes
              }
            });
          }
        });

        this.setState({ friends: tempFriends });
      }
    }
  }

  handleTwilioResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.twilioAPICallID) {
      if (responseJson?.token) {
        this.createConnection(responseJson.token);
      }
    }
  }

  handleConversationResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.fetchConversationId) {
      const user = responseJson?.data?.attributes;
      const conversation_sids = user?.conversation_sid;

      if (!conversation_sids.length) {
        const user_id = Number(responseJson?.data?.id)
        sessionStorage.setItem("responseJson", JSON.stringify(responseJson));
        this.createConversation(user_id);
      } else {
        const conversation_id = conversation_sids[conversation_sids.length - 1];
        this.handleNewChat(responseJson, conversation_id);
      }
    }
  }

  handleConnectionResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createConnectionAPIId) {
      if (responseJson?.data?.conversation_sid) {
        const conversation_sid = responseJson?.data?.conversation_sid;
        sessionStorage.setItem("conversation_sid", conversation_sid);
        const data = JSON.parse(sessionStorage.getItem("responseJson") as string);
        this.handleNewChat(data, conversation_sid);
      }
    }
  }

  handleDisconnectResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.disconnectAPIId) {
      this.toggleConfirmation();
      this.handleOpen("success", responseJson?.message || "You are no longer connected.");
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleUsersResponse(apiRequestCallId, responseJson);
        this.handleMessagesResponse(apiRequestCallId, responseJson);
        this.handleFriendsReponse(apiRequestCallId, responseJson);
        this.handleTwilioResponse(apiRequestCallId, responseJson);
        this.handleConversationResponse(apiRequestCallId, responseJson);
        this.handleConnectionResponse(apiRequestCallId, responseJson);
        this.handleDisconnectResponse(apiRequestCallId, responseJson);
      }

      if (errorReponse) {
        console.log("errorReponse:ChatController", errorReponse);
      }
    }
  }

  handleNewChat = (responseJson: any, conversation_id: string) => {
    const user = responseJson?.data?.attributes;
    const user_id = Number(responseJson?.data?.id)

    const newUser = {
      user_data: { id: user_id, ...user },
      profile_image: user.profile_image,
      body: '',
      conversation_sid: conversation_id,
      unread: 0,
      is_connected: true
    }

    this.setState(prev => ({
      chattingWith: {
        ...prev.chattingWith,
        profile_image: user.profile_image,
        user_name: user.user_name, conversation_id,
        full_name: user.full_name, user_id: user_id,
        last_message: ''
      }, coming_from_profile: true
    }), () => {
      this.setState(prev => ({
        search:
          { isVisible: false, searchKeyword: '' },
        filterUsers: [newUser, ...prev.users],
        hasAlreadyChat: true
      }));
      this.handleClose();
      this.handleSelectUser();
    });
  }

  // handle typing and unread message count
  manageEvents = (conversation: any, isConectedIndx: number) => {
    if (this.state.connectedConversationIds.includes(conversation.sid)) return;

    this.setState(prev => ({ connectedConversationIds: [...prev.connectedConversationIds, conversation.sid] }), () => {
      // add typing lisenter.
      conversation.on('typingStarted', (participant: any) => {
        this.updateTypingIndicator(participant, true);
      });

      conversation.on('typingEnded', (participant: any) => {
        this.updateTypingIndicator(participant, false);
      });

      // Check for unread messages
      if (isConectedIndx === -1) return;
      conversation.getUnreadMessagesCount().then((unread_count: number) => {
        const tempUsers = [...this.state.filterUsers];
        const user = tempUsers[isConectedIndx];

        const data = JSON.parse(sessionStorage.getItem("responseJson") as string);
        if (data) {
          // if we've data, that's mean user is search through search box, 
          // in thi case, we don't want to increse the user unread msg count !
          sessionStorage.removeItem("responseJson");
        }

        if (unread_count === null && !data) {
          // if user didn't start chatting.
          conversation.getMessagesCount().then((total_count: number) => {
            user.unread = total_count
          });
        }
        else user.unread = unread_count;

        tempUsers[isConectedIndx] = user;
        this.setState({ users: tempUsers });
      });
    });
  }

  // twilio connection
  createConnection = async (token: string) => {
    (window as any).conversationsClient = ConversationsClient;
    this.conversationsClient = new ConversationsClient(token);

    this.conversationsClient.on("conversationJoined", async (conversation: any) => {
      this.setState(prev => ({ allConversations: [...prev.allConversations, conversation] }), () => {
        const { allConversations } = this.state;
        allConversations.forEach(async (conversation) => {

          // create message socket connection 
          if (!this.state.boundConversations.has(conversation)) {
            let newConversation = conversation;

            newConversation.on('messageAdded', (m: any) => this.messageAdded(m, newConversation));
            this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
          }

          // check because we only need to connect with users, that are currenly connected.
          const isConectedIndx = this.state.filterUsers.findIndex((each) => each.is_connected && each.conversation_sid === conversation.sid);
          if (isConectedIndx !== -1) this.manageEvents(conversation, isConectedIndx);

          // If user connects for first time  
          if (this.state.coming_from_profile && isConectedIndx === -1) this.manageEvents(conversation, isConectedIndx);
        });
      });

      // select the current user 
      if (this.state.chattingWith.user_name)
        this.handleSelectUser();
    });
  }

  // handle Typing 
  updateTypingIndicator = (participant: any, isTyping: boolean) => {
    if (participant.state.identity !== localStorage.getItem("userName"))
      this.setState({ typing: { conversation_id: participant.conversation.sid, isTyping } });
  }

  // handle user selection
  handleSelectUser = () => {
    const { allConversations, chattingWith } = this.state;

    if (allConversations.length) {
      allConversations.every((conversation) => {
        if (this.state.selectedConversation?.sid !== conversation.sid &&
          conversation.sid == chattingWith.conversation_id) {

          this.setState({ selectedConversation: conversation }, () =>
            this.loadMessages()
          );
          return false;
        }

        return true;
      })
    }
  }

  handleSelectConversation = (conversation_sid: string, userId: string | number) => {
    if (!conversation_sid) {
      // this.props.history.push("UserProfile", { userId });
      this.fetchConversation(userId);
      return;
    }

    // remove chatting with, if no messages sent
    if (this.state.chattingWith.user_name && (
      !this.state.chattingWith.last_message && !this.state.hasAlreadyChat)) {
      if (confirm('Are you sure you want to leave this chat?')) {
        this.setState({ hasAlreadyChat: true });
      } else {
        return;
      }
    }

    const selectedUser = this.state.filterUsers.filter((user) => user.conversation_sid === conversation_sid);
    if (!selectedUser.length) return;

    const user = selectedUser[0];
    if (user.conversation_sid === this.state.chattingWith.conversation_id) return;

    if (!this.state.hasAlreadyChat) {
      this.setState({ hasAlreadyChat: true }, () => {
        this.loadUsers();
      });
    }

    if (user?.unread) {
      // remove current user unread from total notification count.
      sessionStorage.setItem("remove_count", JSON.stringify(user.unread));
      window.dispatchEvent(new Event("storage"));

      user.unread = 0;
    }

    this.setState(prev => ({
      chattingWith: {
        ...prev.chattingWith, conversation_id: conversation_sid, user_name: user.user_data.user_name,
        messages: [], profile_image: user.profile_image, last_message: user.body,
        full_name: user.user_data.full_name, user_id: user.user_data.id
      }
    }), () => {
      this.props.history.push(`?chat_id=${this.state.chattingWith.conversation_id}`);
      this.handleSelectUser();
    });
  }

  // add the message 
  messageAdded = (message: any, targetConversation: any) => {

    const temp_users = [...this.state.filterUsers];
    let selectedUserIndx: number;

    if (targetConversation === this.state.selectedConversation) {
      this.setState(prev => ({
        chattingWith: {
          ...prev.chattingWith,
          last_message: message.state.body,
          messages: [...prev.chattingWith.messages, { ...message.state, created_at: message.state.timestamp }]
        }
      }), () => {
        // update last message index
        this.state.selectedConversation.updateLastReadMessageIndex(
          message.state.index || this.state.chattingWith.messages.length - 1);
      });

      // update current user last message
      selectedUserIndx = temp_users.findIndex((user) => user.conversation_sid === this.state.chattingWith.conversation_id);
    } else {
      selectedUserIndx = temp_users.findIndex((user) => user.conversation_sid === targetConversation.sid);
    }

    if (selectedUserIndx === -1) return;

    const selectedUser = temp_users[selectedUserIndx];

    const { author, body, timestamp, sid } = message.state;
    selectedUser.author = author;
    selectedUser.body = body;
    selectedUser.created_at = timestamp;
    selectedUser.message_sid = sid;

    if (targetConversation.sid !== this.state.selectedConversation?.sid) {
      selectedUser.unread = !selectedUser?.unread ? 1 : selectedUser.unread + 1;
    }

    temp_users[selectedUserIndx] = selectedUser;

    // move chat to top
    temp_users.splice(selectedUserIndx, 1);
    temp_users.unshift(selectedUser);

    this.setState({ users: temp_users, filterUsers: temp_users });

    if (this.state.isWindowBlur || targetConversation !== this.state.selectedConversation) {
      new Notification(`A new message from ${author}`, {
        body,
      });
    }
  };

  getTwilioToken = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.twilioAPICallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.twilioAPI
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.twilioMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  sendMessage = async (e: React.FormEvent | null) => {
    if (e) e.preventDefault();

    if (this.state.loading) {
      alert("Please wait while chats are loading....");
      return;
    }

    // call API insted of using inbuild method, because we want to also store the profile picture
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.sendMessageAPI}?user_id=${this.state.chattingWith.user_id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sendMessageMethod
      );

      const textarea = this.inputRef.current;
      if (!textarea?.value.trim().length) {
        this.handleOpen("error", "Please enter a message.");
        return;
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          conversation_sid: this.state.chattingWith.conversation_id,
          message: textarea.value
        })
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  loadUsers = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loadUserAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loadUsersAPI
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loadUsersMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  loadMessages = () => {
    const token = localStorage.getItem("token");

    if (token) {
      this.setState({ loading: true });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loadMessagesAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.loadMessagesAPI}?conversation_sid=${this.state.chattingWith.conversation_id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loadMessagesMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  fetchConversation = (profile_id: string | number) => {
    const token = localStorage.getItem("token");

    if (token) {
      this.setState({ loading: true });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.fetchConversationId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.fetchConversationAPI}/${profile_id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.fetchConversationMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  onSearch = (keyword: string) => {
    this.setState(prev => ({ search: { ...prev.search, searchKeyword: keyword.toLowerCase() } }), () => {
      const { users, search: { searchKeyword }, friends } = this.state;

      if (!searchKeyword.trim().length) {
        this.setState({ filterUsers: users });
        return;
      }

      const IDS: number[] = [];
      const finalData: any[] = [];

      const LoopData = (data: any[]) => {
        data.forEach((user) => {
          const user_id = user.user_data.id;
          if (IDS.includes(user_id)) return;

          if (user.user_data.full_name.toLowerCase().includes(searchKeyword)) {
            IDS.push(user_id);
            finalData.push(user);
          }
        });
      }

      LoopData(users);
      LoopData(friends);

      this.setState({ filterUsers: finalData });
    });
  }

  // handle more options 
  toggleConfirmation = () => {
    this.setState(prev => ({ isConfirmationOpen: !prev.isConfirmationOpen }));
  }

  handleDisconnect = (user_choice: boolean) => {
    if (!user_choice) {
      this.toggleConfirmation();
      return;
    }

    const user_id = this.state.chattingWith.user_id || localStorage.getItem("selectedUserId");
    if (!user_id) {
      return;
    };

    // Call disconnect API
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.disconnectAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.disconnectUserAPI}?account_id=${user_id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.disconnectUserMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({})
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // sync the state
    const temp_users = [...this.state.users];
    const selectedUserIndx = temp_users.findIndex((user) => user.conversation_sid === this.state.chattingWith.conversation_id);

    if (selectedUserIndx === -1) return;
    // remove the user from the array
    temp_users.splice(selectedUserIndx, 1);
    // update the state
    this.setState({
      users: temp_users, filterUsers: temp_users, selectedConversation: null, chattingWith: {
        conversation_id: "", full_name: "", messages: [], profile_image: "", user_id: null, user_name: "", last_message: ""
      }
    });
  }

  fetchFriends = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loadfriendsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.friendsRequests}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.friendsRequestsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShowRooms = () => {
    this.props.history.push("UserProfile?show=rooms", {
      userId: this.state.chattingWith.user_id
    });
  }

  // create conversation IF, no conversation found
  createConversation = (userID: number) => {
    let token = localStorage.getItem("token");

    if (token) {
      let payload = {
        "account_id": userID
      }

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConnectionAPIId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendConversationRequestApiEndPoint
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(payload)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypePost
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  // handle window focus 
  handleVisibilityChange = () => {
    this.setState({ isWindowBlur: document.visibilityState === 'visible' });
  }

  // handle Snackbar
  handleOpen = (type: AlertProps["severity"], message: string) => {
    this.setState({ snackbar: { isOpen: true, message, type } })
  }

  handleClose = () => {
    this.setState({ snackbar: { ...this.state.snackbar, isOpen: false } })
  }

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  }

  handleMenuOpen = () => {
    if (this.state.isMenuOpen) {
      this.handleMenuClose();
      return;
    }

    this.setState({ isMenuOpen: true });
  }

  handleKeyPress = (event: KeyboardEvent) => {
    // Check if Enter key is pressed
    if (event.key === 'Enter') {
      // Detect Mac using user agent string and feature detection
      const isMac = window.navigator.userAgent.includes('Mac') && 'ontouchstart' in window === false;

      // Check if Cmd (Mac) or Ctrl (Windows) is pressed
      if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
        // send message 
        if (this.state.selectedConversation) this.sendMessage(null);
      }
    }
  }
  // Customizable Area End
}
